import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import { useLocation, useNavigate } from "react-router-dom";

import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";

import menu from "../../assets/images/icons/menu.png";
import logo from "../../assets/images/logo.png";
import user_account from "../../assets/images/user_account.png";
import { logout } from "../../actions/accountActions";


import "../../website.css";

import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import "react-responsive-modal/styles.css";

const Header = () => {
  const navbar_tabs = [
    {
      label: "Home",
      value: "/",
    },
    {
      label: "About Us",
      value: "/aboutus",
    },
    // {
    //   label: "Pricing",
    //   value: "/pricing",
    // },
    {
      label: "Technology",
      value: "/technology",
    },
    {
      label: "Book a Demo",
      value: "https://calendly.com/spoofguard",
    },
  ];

  const auth_navbar_tabs = [
    {
      label: "Home",
      value: "/",
    },
    {
      label: "About Us",
      value: "/aboutus",
    },
    {
      label: "Pricing",
      value: "/pricing",
    },
    {
      label: "Technology",
      value: "/technology",
    },
    {
      label: "Dashboard",
      value: "/dashboard/index",
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();

  const [url, setUrl] = useState(null);
  const [showMobileNav, setShowMobileNav] = useState(false);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const account = useSelector((state) => state.account);

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  // const mobileMenu = () => {
  //   setMobile(!mobile);
  // };

  const handleLogout = async () => {
    try {
      localStorage.clear();
      dispatch(logout());
      navigate("/");
    } catch (error) {
      enqueueSnackbar("Unable to logout", {
        variant: "error",
      });
    }
  };

  const [open, setopen] = useState(false);

  const onOpenModal = () => setopen(true);
  const onCloseModal = () => setopen(false);

  const buttonStyle = { backgroundColor: "#ffffff00" }

  return (

    <header>
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <div className="logo">
            <img src={logo} alt="Spoofguard logo" />
          </div>
          <nav className="navbar navbar-expand-md navbar-light">
            <button onClick={() => { setShowMobileNav(!showMobileNav) }} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" style={{ ...buttonStyle }}>
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className={`navbar-collapse independent-dropdown ${!showMobileNav ? "hide" : ""}`} id="navbarNav">
              <ul className="navbar-nav ml-auto">
                {navbar_tabs?.map((item, index) => (
                  <>
                    {item.label !== "Book a Demo" ? <li key={index} className="nav-item"><a className="nav-link" href={item.value}>{item.label}</a></li> : <li key={index} className="nav-item"><a className="nav-link book-demo" rel="noreferrer" target="_blank" href={item.value}>{item.label}</a></li>}
                  </>
                ))}
              </ul>
            </div>

          </nav>

          <div className="login-btn">
            <a href="https://calendly.com/spoofguard" target="_blank" rel="noreferrer" className="demo-button">
              Book a Demo
            </a>
            <a href='/login' className="learn-more">Login</a>
            <a href='/register' className="learn-more-new">Register</a>
          </div>
        </div>
      </div>
    </header>

    // <div classNameName="bg-secondary rounded-b-3xl">
    //   <div classNameName="max-w-xl lg:max-w-6xl mx-auto bg-header_bg px-6">
    //     <div classNameName="flex justify-between items-center py-[20px]">
    //       <img src={logo} classNameName="h-[30px] lg:h-[40px]" alt="" />

    //       {!account.user ? (
    //         <div classNameName="hidden justify-between items-center text-white lg:flex">
    //           {navbar_tabs.map((item, index) => {
    //             if (item.value === url) {
    //               return (
    //                 <a
    //                   key={index}
    //                   classNameName={
    //                     "ml-8 cursor-pointer hover:text-primary text-primary"
    //                   }
    //                   href={item.value}
    //                 >
    //                   {item.label}
    //                 </a>
    //               );
    //             } else {
    //               return (
    //                 <a
    //                   key={index}
    //                   classNameName="ml-8 cursor-pointer hover:text-primary"
    //                   href={item.value}
    //                 >
    //                   {item.label}
    //                 </a>
    //               );
    //             }
    //           })}
    //         </div>
    //       ) : (
    //         <div classNameName="hidden justify-between items-center text-white lg:flex">
    //           {auth_navbar_tabs.map((item, index) => {
    //             if (item.value === url) {
    //               return (
    //                 <a
    //                   key={index}
    //                   classNameName={
    //                     "ml-8 cursor-pointer hover:text-primary text-primary"
    //                   }
    //                   href={item.value}
    //                 >
    //                   {item.label}
    //                 </a>
    //               );
    //             } else {
    //               return (
    //                 <a
    //                   key={index}
    //                   classNameName="ml-8 cursor-pointer hover:text-primary"
    //                   href={item.value}
    //                 >
    //                   {item.label}
    //                 </a>
    //               );
    //             }
    //           })}
    //         </div>
    //       )}

    //       {!account.user ? (
    //         <div>
    //           <div classNameName="hidden lg:flex items-center justify-center">
    //             <a
    //               classNameName="bg-primary text-white min-w-[200px] h-[40px] rounded-full flex items-center justify-center"
    //               href="/login"
    //             >
    //               <p>Login / Register</p>
    //             </a>
    //             {/* <Button color='primary' title="Register" /> */}
    //           </div>
    //           <div classNameName="block lg:hidden">
    //             <img
    //               src={menu}
    //               classNameName="w-[20px]"
    //               onClick={onOpenModal}
    //               alt=""
    //             />
    //           </div>
    //         </div>
    //       ) : (
    //         <div classNameName="flex gap-2 items-center">
    //           <Menu
    //             menuButton={
    //               <MenuButton>
    //                 <div classNameName="flex items-center gap-3">
    //                   <img src={user_account} alt="" />
    //                   <p classNameName="text-sm md:text-base hidden md:block text-white">
    //                     {account.user.first_name} {account.user.last_name}
    //                   </p>
    //                 </div>
    //               </MenuButton>
    //             }
    //             transition
    //           >
    //             <MenuItem onClick={handleLogout}>Logout</MenuItem>
    //           </Menu>
    //           <div classNameName="block lg:hidden">
    //             <img
    //               src={menu}
    //               classNameName="w-[20px]"
    //               onClick={onOpenModal}
    //               alt=""
    //             />
    //           </div>
    //         </div>
    //       )}
    //     </div>
    //     <div classNameName="s">
    //       <Modal
    //         open={open}
    //         onClose={onCloseModal}
    //         showCloseIcon={false}
    //         classNameNames={{
    //           overlay: "customOverlay",
    //           modal: "homeheader",
    //         }}
    //       >
    //         <div classNameName="p-4 bg-secondary w-full">
    //           <div classNameName="pb-6">
    //             <img src={logo} classNameName="h-[40px]" alt="" />
    //           </div>
    //           {!account.user ? (
    //             <div classNameName="flex flex-col gap-4 text-white">
    //               {navbar_tabs.map((item, index) => {
    //                 if (item.value === url) {
    //                   return (
    //                     <a
    //                       key={index}
    //                       classNameName={
    //                         "cursor-pointer hover:text-primary text-primary"
    //                       }
    //                       href={item.value}
    //                     >
    //                       {item.label}
    //                     </a>
    //                   );
    //                 } else {
    //                   return (
    //                     <a
    //                       key={index}
    //                       classNameName="cursor-pointer hover:text-primary"
    //                       href={item.value}
    //                     >
    //                       {item.label}
    //                     </a>
    //                   );
    //                 }
    //               })}
    //               <a classNameName="text-white cursor-pointer" href="/login">
    //                 <p>Login / Register</p>
    //               </a>
    //             </div>
    //           ) : (
    //             <div classNameName="flex flex-col gap-4 text-white">
    //               {auth_navbar_tabs.map((item, index) => {
    //                 if (item.value === url) {
    //                   return (
    //                     <a
    //                       key={index}
    //                       classNameName={
    //                         "cursor-pointer hover:text-primary text-primary"
    //                       }
    //                       href={item.value}
    //                     >
    //                       {item.label}
    //                     </a>
    //                   );
    //                 } else {
    //                   return (
    //                     <a
    //                       key={index}
    //                       classNameName="cursor-pointer hover:text-primary"
    //                       href={item.value}
    //                     >
    //                       {item.label}
    //                     </a>
    //                   );
    //                 }
    //               })}
    //             </div>
    //           )}
    //         </div>
    //       </Modal>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Header;
