import React, { useEffect, useState } from "react";
import {
    Box,
    CircularProgress,
    Container,
    Paper,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    CardHeader,
    LinearProgress,
} from "@mui/material";

import DashboardLayout from "../../components/Dashboard/layout";
import { deleteTakeDown, getAllTakeDowns } from "../../api";
import TakeDownPageTable from "../../components/takedown-page-table";
import { useLocation } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

const TakeDown = () => {
    const [loading, setLoading] = useState(false);
    const [loadTable, setLoadTable] = useState(true);
    const [takeDowns, setTakeDowns] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [pendingDelete, setPendingDelete] = useState(null);

    const location = useLocation();
    const takeDownId = location?.state?.takedown_id;

    useEffect(() => {
        if (loadTable) {
            setLoading(true);
            getAllTakeDowns()
                .then((response) => {
                    setTakeDowns(response?.data || []);
                    setLoading(false);
                })
                .catch((err) => {
                    console.error(err);
                    enqueueSnackbar("Unable to load takedowns", {
                        variant: "error",
                    });
                    setLoading(false);
                });

            setLoadTable(false)
        }
    }, [loadTable]);


    const handleDeleteModal = (takedown) => {
        setOpenDialog(true);
        setPendingDelete(takedown.uid)
    }

    const handleDeleteModalClose = () => {
        setOpenDialog(false);
        setPendingDelete(null)
    }

    const handleTakeDownDelete = () => {

        deleteTakeDown(pendingDelete).then((response) => {
            setOpenDialog(false);
            enqueueSnackbar("Takedown deleted successfully", {
                variant: "success",
            });
            setLoadTable(true)
        }).catch(() => {
            enqueueSnackbar("Unable to delete takedown", {
                variant: "error",
            });
        })
    }

    return (
        <DashboardLayout>
            <Container maxWidth="100%" sx={{ p: 3}}>
                <Box>
                    <Paper elevation={3} sx={{ p: 0, mb: 3 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
                            <CardHeader title="Takedown" sx={{ p: 0 }} />
                        </Box>
                        <Box>
                            {loading &&
                                <LinearProgress />
                            }
                        </Box>
                        <TakeDownPageTable takeDownId={takeDownId} takeDowns={takeDowns} handleDeleteModal={handleDeleteModal} />
                    </Paper>
                </Box>
            </Container>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Delete Takedown</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete takedown? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDeleteModalClose()}>Cancel</Button>
                    <Button onClick={() => handleTakeDownDelete()} color="error">
                        Delete Takedown
                    </Button>
                </DialogActions>
            </Dialog>
        </DashboardLayout>
    );
};

export default TakeDown;