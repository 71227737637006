import React, { useState } from "react";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
} from "@mui/material";
import CollapsibleRowTakeDown from "../collapsible-row-takedown";

const TakeDownPageTable = ({
    takeDownId,
    takeDowns,
    handleDeleteModal,
}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Paginated data
    const paginatedTakeDowns = takeDowns.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    return (
        <>
            <TableContainer sx={{ maxHeight: "calc(100vh - 200px)", minHeight: "500px" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Malicious Domain</TableCell>
                            <TableCell>Malicious IP</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Takedown Status</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedTakeDowns.map((takeDown) => (
                            <CollapsibleRowTakeDown
                                key={takeDown.uid}
                                takeDownId={takeDownId}
                                takeDown={takeDown}
                                onTakeDownDelete={handleDeleteModal}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={takeDowns.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25, 50]}
            />
        </>
    );
};

export default TakeDownPageTable;
