import React, { useState, useEffect } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  LinearProgress,
  Box,
  Typography,
  Paper
} from '@mui/material';

const steps = [
  'Initial Scan',
  'Analyze Results',
  'Domain Investigation',
  'Setup Monitoring',
  'TakeDown Domain'
];

const ModuleProgress = ({ name, total, progress }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 2 }}>
      <Typography variant="body1" sx={{ minWidth: 250 }}>
        {name}
      </Typography>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{ flexGrow: 1 }}
      />
      {/* <Typography variant="body1" sx={{ minWidth: 50, textAlign: 'right' }}>
        {total}
      </Typography> */}
    </Box>
  );
};

const ScanProgress = ({ modules, setScanProgressDone }) => {
  const [progress, setProgress] = useState({});
  const [activeStep, setActiveStep] = useState(0);

  // Initialize progress and simulate updates
  useEffect(() => {
    const initialProgress = {};
    modules.forEach((module) => {
      initialProgress[module.name] = 0;
    });
    setProgress(initialProgress);

    // Simulate progress updates
    modules.forEach((module) => {
      const minDuration = 10000;  // 10s
      const maxDuration = 30000;  // 30s
      const duration = Math.random() * (maxDuration - minDuration) + minDuration;
      const interval = 50;
      const steps = duration / interval;
      let currentProgress = 0;

      const timer = setInterval(() => {
        const randomIncrement = (100 / steps) + Math.random() * 0.5 - 0.25;
        currentProgress += randomIncrement;


        if (currentProgress >= 100) {
          currentProgress = 100;
          clearInterval(timer);
        }
        setProgress((prev) => ({
          ...prev,
          [module.name]: currentProgress
        }));
      }, interval);
    });
  }, [modules]);

  /**
   *  Check if all modules are complete
   */
  useEffect(() => {
    const allComplete = modules.every((m) => progress[m.name] >= 100);
    if (modules.length > 0 && allComplete) {
      // Notify parent component
      setScanProgressDone(true);
    }
  }, [modules, progress, setScanProgressDone]);

  // Split modules into two columns
  const half = Math.ceil(modules.length / 2);
  const leftModules = modules.slice(0, half);
  const rightModules = modules.slice(half);

  return (
    <Paper className="scan-progress" sx={{ p: 4, maxWidth: 1200, margin: 'auto' }}>
      <Stepper className="scan-progress-steps" activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box className="scan-progress-loader" sx={{ display: 'flex', alignItems: 'center', mb: 4, gap: 2 }}>
        <CircularProgress size={20} />
        <Typography>Please wait while the scan is initiating...</Typography>
      </Box>

      <Typography variant="h6" sx={{ mb: 2 }}>
        Domain Scanning Variations
      </Typography>


      <Box className="scan-progress-results" sx={{ display: 'flex', gap: 4 }}>
        <Box sx={{ flex: 1 }}>
          {leftModules.map((module) => (
            <ModuleProgress
              key={module.name}
              name={module.name}
              total={module.total}
              progress={progress[module.name] || 0}
            />
          ))}
        </Box>
        <Box sx={{ flex: 1 }}>
          {rightModules.map((module) => (
            <ModuleProgress
              key={module.name}
              name={module.name}
              total={module.total}
              progress={progress[module.name] || 0}
            />
          ))}
        </Box>
      </Box>
    </Paper>
  );
};

export default ScanProgress;
