import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Link,
  InputAdornment
} from '@mui/material';

import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import { login } from "../actions/accountActions";

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    return password.length >= 8 && hasUpperCase && hasLowerCase && hasNumber;
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (!newEmail) {
      setEmailError('Email is required');
    } else if (!validateEmail(newEmail)) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (!newPassword) {
      setPasswordError('Password is required');
    } else if (!validatePassword(newPassword)) {
      setPasswordError('Password must contain at least one uppercase letter, one lowercase letter, and one number');
    } else {
      setPasswordError('');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email) setEmailError('Email is required');
    if (!password) setPasswordError('Password is required');
    if (!validatePassword(password)) {
      setPasswordError('Password must contain at least one uppercase letter, one lowercase letter, and one number');
    }
    if (email && password && !emailError && !passwordError) {
      try {
        await dispatch(login(email, password));
        enqueueSnackbar("Login Success!", {
          variant: "success",
        });
        const currentURL = new URL(window.location.href);
        const redirectURL = currentURL.searchParams.get('redirecturl');

        if (redirectURL) {
          navigate(redirectURL);
        } else if (localStorage.getItem('anonymous')) {
          localStorage.removeItem('anonymous');
          navigate('/dashboard/scan');
        } else {
          navigate('/dashboard/index');
        }
      } catch (error) {
        const message = error.response.data.detail;
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    }
  };

  return (
    <Container
      disableGutters
      maxWidth={false} 
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url(/assets/images/account/SG_login_man_at_desk.webp)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'white',
          padding: 4,
          borderRadius: "4px",
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          width: '600px',
        }}
      >
        <Typography
          component="h1"
          variant="h3"
          sx={{
            mb: 1,
            fontWeight: 'bold',
          }}
        >
          Login
        </Typography>
        <Typography
          variant="h6"
          sx={{
            mb: 4,
            color: 'text.secondary',
          }}
        >
          Login to your Account
        </Typography>

        <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            placeholder="Email"
            name="email"
            autoComplete="email"
            value={email}
            onChange={handleEmailChange}
            error={!!emailError}
            helperText={emailError}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon sx={{ color: 'text.secondary' }} />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '4px',
              }
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            placeholder="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={handlePasswordChange}
            error={!!passwordError}
            helperText={passwordError}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon sx={{ color: 'text.secondary' }} />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '4px',
              }
            }}
          />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
            <Link href="/forgotpassword" underline="none" sx={{ color: '#2196f3' }}>
              Forgot Password
            </Link>
          </Box>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              borderRadius: '4px',
              padding: '12px',
              textTransform: 'none',
              fontSize: '1.1rem',
              backgroundColor: '#2196f3',
              '&:hover': {
                backgroundColor: '#1976d2',
              },
            }}
          >
            Login
          </Button>

          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Typography component="span" sx={{ mr: 1 }}>
              Don't have an account?
            </Typography>
            <Link href="/register" underline="none" sx={{ color: '#2196f3' }}>
              Register now
            </Link>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
