import React, { useEffect, useState } from "react";
import {
    Box,
    CircularProgress,
    Typography,
    TableCell,
    TableRow,
    IconButton,
    Grid,
    Card,
    CardContent,
    TableBody,
    Chip,
    Link,
    Table,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import { RemoveRedEyeOutlined } from "@mui/icons-material";

const CollapsibleRowTakeDown = ({ takeDownId, takeDown, onTakeDownDelete }) => {
    const [open, setOpen] = useState(false);

    const handleRowClick = () => {
        setOpen(!open);
    };

    const handleDeleteClick = (e) => {
        e.stopPropagation(); // Prevent row click event
        onTakeDownDelete(takeDown);
    };

    useEffect(() => {
        if (takeDownId) {
            if (takeDownId === takeDown.uid) {
                setOpen(true)
            }
        }
    }, [takeDownId, takeDown.uid])

    return (
        <>
            <TableRow
                hover
                onClick={handleRowClick} // Make the whole row clickable
                style={{ cursor: "pointer" }}
            >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation(); // Prevent row click when toggling
                            setOpen(!open);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{takeDown.malicious_domain}</TableCell>
                <TableCell>{takeDown.malicious_ip}</TableCell>
                <TableCell>
                    {new Date(takeDown.created_at).toLocaleDateString()}
                </TableCell>
                <TableCell>Pending</TableCell>
                <TableCell>
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation(); // Prevent row click
                            setOpen(!open);
                        }}
                    >
                        <RemoveRedEyeOutlined />
                    </IconButton>

                    <IconButton
                        onClick={handleDeleteClick} // Prevent row click when deleting
                    >
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            {open && (
                <TableRow>
                    <TableCell colSpan={8} sx={{ pb: 2, pt: 2 }}>
                        {takeDown ? (
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Additional Information
                                </Typography>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                Created At
                                            </TableCell>
                                            <TableCell>
                                                {new Date(takeDown.created_at).toLocaleString()}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                Original IP
                                            </TableCell>
                                            <TableCell>{takeDown.original_ip}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                Registrar Abuse Email
                                            </TableCell>
                                            <TableCell>
                                                {takeDown.registrar_abuse_email_sent
                                                    ? "Sent"
                                                    : "Not Sent"}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                Hosting Abuse Email
                                            </TableCell>
                                            <TableCell>
                                                {takeDown.hosting_abuse_email_sent
                                                    ? "Sent"
                                                    : "Not Sent"}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                Blacklists
                                            </TableCell>
                                            <TableCell>
                                                {takeDown.blacklists[0]
                                                    .split(",")
                                                    .map((blacklist, index) => (
                                                        <Chip
                                                            key={index}
                                                            label={blacklist}
                                                            size="small"
                                                            style={{ margin: "2px" }}
                                                        />
                                                    ))}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                Signed Power
                                            </TableCell>
                                            <TableCell>
                                                <Link
                                                    href={`${process.env.REACT_APP_BASIC_API_URL}${takeDown.signed_power}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Download Signed Power
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                {takeDown.additional_notes && (
                                    <Box sx={{ mt: 2, mb: 4 }}>
                                        <Typography variant="h6" component="div" mb={2}>
                                            Submitted Notes
                                        </Typography>
                                        <Typography variant="p" component="div">
                                            {takeDown.additional_notes}
                                        </Typography>
                                    </Box>
                                )}
                                <Box sx={{ mt: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Card sx={{ height: "100%" }}>
                                                <Box
                                                    sx={{
                                                        height: 400,
                                                        overflow: "auto",
                                                        backgroundColor: "grey.200",
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            takeDown.original_screenshot_link ||
                                                            "/placeholder.svg?height=400&width=300"
                                                        }
                                                        alt="Original Screenshot"
                                                        style={{
                                                            width: "100%",
                                                            height: "auto",
                                                            display: "block",
                                                        }}
                                                    />
                                                </Box>
                                                <CardContent>
                                                    <Typography variant="h6" component="div">
                                                        Original Screenshot
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Card sx={{ height: "100%" }}>
                                                <Box
                                                    sx={{
                                                        height: 400,
                                                        overflow: "auto",
                                                        backgroundColor: "grey.200",
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            takeDown.malicious_screenshot_link ||
                                                            "/placeholder.svg?height=400&width=300"
                                                        }
                                                        alt="Malicious Screenshot"
                                                        style={{
                                                            width: "100%",
                                                            height: "auto",
                                                            display: "block",
                                                        }}
                                                    />
                                                </Box>
                                                <CardContent>
                                                    <Typography variant="h6" component="div">
                                                        Malicious Screenshot
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        ) : (
                            <Box display="flex" justifyContent="center">
                                <CircularProgress size={24} />
                            </Box>
                        )}
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default CollapsibleRowTakeDown;
