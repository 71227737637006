import React, { useEffect, useState } from "react";
import { FallingLines } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import activateFailed from "../assets/images/activatefailed.png";
import activateSuccess from "../assets/images/activatesuccess.png";
import ShadowBlog from "../components/common/shadowBlog";
import { activateaccount } from "../actions/accountActions";
import { Box, Button, Container, Typography } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";

const Accountactivate = () => {
  const { id, token } = useParams();
  const dispatch = useDispatch();

  const [isSuccess, setSuccess] = useState();

  const data = {
    uid: id,
    token: token,
  };

  const handleVerify = async () => {
    try {
      await dispatch(activateaccount(data));
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
    }
  };

  useEffect(() => {
    handleVerify();
  }, []);

  return (
    <>
      {isSuccess === true ? (
        <Container
          disableGutters
          maxWidth={false}
          sx={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: 'url(/assets/images/account/SG_login_man_at_desk.webp)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: 'white',
              padding: 4,
              borderRadius: "4px",
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              width: '600px'
            }}
          >
            <CheckCircleOutline color="primary" sx={{ width: "60px", height: "60px" }} />
            <Typography align="center" variant="h3" component="h1" sx={{ fontWeight: 'bold', mb: 1 }}>
              Account Verified
            </Typography>
            <Typography align="center" variant="h5" sx={{ mb: 4, color: 'text.secondary' }}>
              Your account is now active! Please log in to access your account.
            </Typography>

            <Button
              href="/login"
              variant="contained"
              sx={{
                py: 1.5,
                px: 6,
                borderRadius: '4px',
                backgroundColor: '#52A1E4',
                '&:hover': {
                  backgroundColor: '#4391d4'
                },
                fontSize: "16px"
              }}
            >
              Login
            </Button>
          </Box>
        </Container>
      ) : isSuccess === false ? (
        <Container
          disableGutters
          maxWidth={false}
          sx={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: 'url(/assets/images/account/SG_login_man_at_desk.webp)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}>

        </Container>
      ) : (
        <Container
          disableGutters
          maxWidth={false}
          sx={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: 'url(/assets/images/account/SG_login_man_at_desk.webp)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}>

        </Container>
      )}
    </>
  );
};

export default Accountactivate;
