import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Box,
  Typography,
  Card,
  LinearProgress,
  Collapse,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  Tooltip,
  IconButton
} from '@mui/material';
import { getSingleDomainAnalysis } from '../../api';
import { InfoIcon } from 'lucide-react';
import { getFormattedDate } from '../../utiles/general';
import { ExpandMore } from '@mui/icons-material';

const dummyObjects = [
  {
    isDummy: true,
    domain: 'reg.ister-access.xyz',
    module_name: 'Kaduu SSL logs search',
    ns_record: 'ns2.register.com',
    a_record: '192.168.1.0',
    mx_record: 'free-mx.register.com',
    http_open: false,
    https_open: false,
    whois_formatted: {},
    screenshot: null,
    urlscore_data: null,
  },
  {
    isDummy: true,
    domain: 'regis.ter.ch',
    module_name: 'TLD',
    ns_record: 're.gi.ster.com',
    a_record: '192.168.10.1',
    mx_record: 'park-mx.freaccount.com',
    http_open: false,
    https_open: false,
    whois_formatted: {},
    screenshot: null,
    urlscore_data: null,
  },
  {
    isDummy: true,
    domain: 'register.forfreeaccess.com',
    module_name: 'Append number letters',
    ns_record: 'ns2.register-account.com',
    a_record: '10.0.1.0',
    mx_record: 'mx.spoof.com',
    http_open: false,
    https_open: false,
    whois_formatted: {},
    screenshot: null,
    urlscore_data: null,
  }
];

export default function PreviewScanTable({ analysis_id, ip_address }) {
  const [activeStep, setActiveStep] = useState(1);
  const [data, setData] = useState([]);
  const [stats, setStats] = useState([]);
  const [scanning, setScanning] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);

  const steps = [
    'Initial Scan',
    'Analyze Results',
    'Domain Investigation',
    'Setup Monitoring',
    'TakeDown Domain'
  ];

  const intervalIdRef = useRef(null);

  useEffect(() => {
    const fetchData = () => {
      if (analysis_id) {
        setScanning(true);
        getSingleDomainAnalysis(analysis_id, ip_address).then((response) => {
          const resultData = response?.data;
          setData(resultData);

          if (
            resultData.total_initial_done === resultData.total_variations ||
            resultData.dns_lookups_formatted.length === 10
          ) {

            clearInterval(intervalIdRef.current);
            setScanning(false);

            resultData.dns_lookups_formatted = [
              ...resultData.dns_lookups_formatted,
              ...dummyObjects
            ];    
          }
        });
      }
    };

    intervalIdRef.current = setInterval(fetchData, 2000);

    return () => clearInterval(intervalIdRef.current);
  }, [analysis_id, ip_address]);

  useEffect(() => {
    if (data) {
      const detectedDomainsCount = data.dns_lookups_formatted?.length || 0;
      const webServers = data.dns_lookups_formatted?.filter(
        (domain) => domain.https_open || domain.http_open
      ).length;

      setStats([
        { label: 'Generated Domain Variations', value: data.total_variations },
        { label: 'Detected Domains', value: detectedDomainsCount },
        { label: 'Web Servers Found', value: webServers },
      ]);
    }
  }, [data]);

  const DetailPanel = ({ row }) => (
    <Box sx={{ p: 2, bgcolor: 'background.paper' }}>
      <Box className="preview-scan-details" sx={{ display: 'flex', flexDirection: 'column', minHeight: '400px' }}>
        <Box sx={{ display: 'flex', flex: 1 }}>
          <Box
            sx={{
              width: '33.33%',
              p: 3,
              borderRight: '1px solid #e0e0e0',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography variant="h6" gutterBottom>Screenshot</Typography>
            <Box sx={{ width: '100%', maxHeight: '30vh', minHeight: '30vh', overflow: 'auto' }}>
              {row.screenshot ? (
                <img
                  src={row.screenshot}
                  alt="Domain Screenshot"
                  style={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: 4,
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  }}
                  onError={(e) => {
                    e.target.onerror = null; // Prevent infinite error loop
                    e.target.style.display = 'none'; // Hide the broken image
                    e.target.parentNode.innerHTML = `
                      <div style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; border: 1px dashed #ccc; border-radius: 4px; font-weight: bold; color: #999;">
                        No screenshot available
                      </div>
                    `;
                  }}
                />
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px dashed #ccc',
                    borderRadius: 4,
                    fontWeight: 'bold',
                    color: '#999',
                  }}
                >
                  No screenshot available
                </Box>
              )}
            </Box>
          </Box>

          {/* Detailed Info Column */}
          <Box sx={{ width: '33.33%', p: 3, borderRight: '1px solid #e0e0e0' }}>
            <Typography variant="h6" gutterBottom>Detailed Information</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography><strong>IP Address:</strong> {row.a_record}</Typography>
              <Typography><strong>HTTPS Open:</strong> {row.https_open ? 'Yes' : 'No'}</Typography>
              <Typography><strong>HTTP Open:</strong> {row.http_open ? 'Yes' : 'No'}</Typography>
              <Typography>
                <strong>Domain Creation Date:</strong> {getFormattedDate(row.whois_formatted?.creation_date)}
              </Typography>
              <Typography>
                <strong>Domain Expiration Date:</strong> {getFormattedDate(row.whois_formatted?.expiration_date)}
              </Typography>
              <Typography>
                <strong>Registrar:</strong> {row.whois_formatted?.registrar ?? 'N/A'}
              </Typography>
              <Typography>
                <strong>Country:</strong> {row.whois_formatted?.country ?? 'N/A'}
              </Typography>
              <Typography>
                <strong>Address:</strong> {row.whois_formatted?.address ?? 'N/A'}
              </Typography>
              <Typography sx={{ wordWrap: 'break-word' }}>
                <strong>Abuse Email:</strong>{' '}
                {Array.isArray(row.whois_formatted?.abuse_email)
                  ? row.whois_formatted?.abuse_email.join(', ')
                  : row.whois_formatted?.abuse_email}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ width: '33.33%', p: 3 }}>
            <Box sx={{ width: '100%', maxHeight: '30vh', minHeight: '30vh', overflow: 'auto' }}>
              <Typography variant="h6" gutterBottom>Risk Score Information</Typography>
              {row.urlscore_data ? (
                <>
                  {row.urlscore_data?.checks
                    ?.filter((x) => x.risk_score)
                    .map((check) => (
                      <Box
                        key={check.category}
                        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
                      >
                        <Typography variant="subtitle1">
                          <strong>{check.category}</strong> -{' '}
                          <strong>Risk score: {check.risk_score}</strong>
                        </Typography>

                        {check.details
                          .filter((x) => x.risk_score)
                          .map((detail) => (
                            <Box mb={3} key={detail.name}>
                              <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                                <strong>{detail.name}</strong>
                                <Tooltip title={detail.background_info}>
                                  <IconButton size="small">
                                    <InfoIcon />
                                  </IconButton>
                                </Tooltip>
                              </Typography>
                              <Typography mt={1}>{detail.message}</Typography>
                            </Box>
                          ))}
                      </Box>
                    ))}
                </>
              ) : (
                <Typography> URL Score details will be available after login.</Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const sortedDnsLookups = (data?.dns_lookups_formatted ?? [])
    .slice()
    .sort((a, b) => {
      const aIsWeb = a.http_open || a.https_open ? 1 : 0;
      const bIsWeb = b.http_open || b.https_open ? 1 : 0;
      return bIsWeb - aIsWeb;
    });

  return (
    <Box className="preview-scan-table-wrapper" sx={{ maxWidth: 1200, margin: '0 auto', padding: 3, marginTop: 3 }}>
      <Stepper className='preview-table-stepper' activeStep={activeStep} sx={{ marginBottom: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Card sx={{ marginBottom: 3, padding: 2, border: '2px solid #1976d2' }}>
        <Box
          className="preview-table-actions"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography>
            Partial results will be displayed below. Log in or register to view the complete
            analysis and insights.
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button href='/register' variant="contained" color="primary">
              Register
            </Button>
            <Button href='/login' variant="contained" color="primary">
              Login
            </Button>
          </Box>
        </Box>
      </Card>

      <Paper sx={{ p: 3, mb: 3 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center'
          }}
        >
          {stats.map((stat, index) => (
            <React.Fragment key={stat.label}>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h4" fontWeight="bold">
                  {stat.value}
                </Typography>
                <Typography color="text.secondary">{stat.label}</Typography>
              </Box>
              {index < stats.length - 1 && (
                <Divider orientation="vertical" flexItem />
              )}
            </React.Fragment>
          ))}
        </Box>
      </Paper>

      {scanning && <LinearProgress />}

      <Paper>
        <Table className='preview-scan-table'>
          <TableHead>
            <TableRow>
              <TableCell>Discovered Domains</TableCell>
              <TableCell>Enumeration Technique</TableCell>
              <TableCell className='desktop-only'>NS Record</TableCell>
              <TableCell className='desktop-only'>A Record</TableCell>
              <TableCell className='desktop-only'>MX Record</TableCell>
              <TableCell className='desktop-only'>Web</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedDnsLookups.map((row, i) => {
              // Identify whether this is one of the last 3 rows
              const isDummy = row.isDummy ? row.isDummy : false;

              return (
                <React.Fragment key={i}>
                  <TableRow
                    hover={!isDummy}
                    sx={{
                      cursor: isDummy ? 'default' : 'pointer',
                      filter: isDummy ? 'blur(5px)' : 'none',
                      userSelect: isDummy ? 'none' : 'unset',
                    }}
                    onClick={
                      !isDummy
                        ? () => setExpandedRow(expandedRow === row.domain ? null : row.domain)
                        : undefined
                    }
                  >
                    <TableCell>
                      {!isDummy && (
                        <IconButton size="small">
                          <ExpandMore />
                        </IconButton>
                      )}
                      {row.domain}
                    </TableCell>
                    <TableCell>{row.module_name}</TableCell>
                    <TableCell className='desktop-only'>{row.ns_record}</TableCell>
                    <TableCell className='desktop-only'>{row.a_record}</TableCell>
                    <TableCell className='desktop-only'>{row.mx_record}</TableCell>
                    <TableCell className='desktop-only'>{row.http_open || row.https_open ? 'True' : 'False'}</TableCell>
                  </TableRow>

                  {!isDummy && (
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse
                          in={expandedRow === row.domain}
                          timeout="auto"
                          unmountOnExit
                        >
                          <DetailPanel row={row} />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
}
