export const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file); // Converts file to Base64 string
  });
};


export const getFormattedDate = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

export const getFormattedDateTime = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
};



export const saveFileToLocalStorage = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64Data = reader.result; // File as Base64
      const fileKey = `file_${Date.now()}`; // Unique key for the file
      localStorage.setItem(fileKey, base64Data); // Save to localStorage
      resolve(fileKey);
    };

    reader.onerror = (error) => reject(error);

    reader.readAsDataURL(file); // Convert file to Base64 string
  });
};

export const getFileFromLocalStorage = (fileKey) => {
  const base64Data = localStorage.getItem(fileKey);
  if (!base64Data) {
    throw new Error("No file found with the provided key");
  }

  // Convert Base64 to a Blob
  const byteString = atob(base64Data.split(",")[1]); // Decode Base64
  const mimeType = base64Data.split(",")[0].split(":")[1].split(";")[0]; // Extract MIME type
  const byteNumbers = new Array(byteString.length).fill().map((_, i) => byteString.charCodeAt(i)); // Create byte array
  const byteArray = new Uint8Array(byteNumbers);

  // Extract the file extension from the MIME type
  const extension = mimeType.split("/")[1]; // e.g., 'png' for 'image/png'

  // Create Blob or File
  const blob = new Blob([byteArray], { type: mimeType });

  // Convert to a File object (e.g., if the API requires a File)
  const file = new File([blob], `uploaded-file.${extension}`, { type: mimeType });

  return file;
};

export const deleteFileFromLocalStorage = (fileKey) => {
  return new Promise((resolve, reject) => {
    try {
      localStorage.removeItem(fileKey);
      resolve(`File with key "${fileKey}" has been deleted.`);
    } catch (error) {
      reject(error);
    }
  });
};