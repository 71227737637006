import { CrisisAlertRounded, DashboardRounded, ForwardToInboxRounded, Gavel, NoteAdd, Policy, RadarRounded, Settings } from "@mui/icons-material";
import ChartSvg from "../components/icons/chart";
import HomeSvg from "../components/icons/home";
import ReportSvg from "../components/icons/report";
import ScanSvg from "../components/icons/scan";
import SettingSvg from "../components/icons/setting";
import TakedownSvg from "../components/icons/takedown";
import { Hammer, HammerIcon } from "lucide-react";

export const dashboardItems = [
  {
    icon: <DashboardRounded />,
    title: "Dashboard",
    id: "index",
    link: "/dashboard/index",
    subtitle: [],
  },
  {
    icon: <Policy />,
    title: "Scan",
    id: "scan",
    link: "/dashboard/scan",
    subtitle: [],
  },
  {
    icon: <RadarRounded />,
    title: "Monitoring",
    id: "monitoring",
    link: "/dashboard/monitoring",
    subtitle: [],
  },
  {
    icon: <CrisisAlertRounded />,
    title: "Alerts ",
    id: "alerts",
    link: "/dashboard/alerts",
    subtitle: [],
  },
  {
    icon: <Gavel />,
    title: "Takedown",
    id: "takedownsite",
    link: "/dashboard/takedowns", 
    subtitle: [],
  },
  {
    icon: <NoteAdd />,
    title: "Reports",
    id: "reports",
    link: "/dashboard/reports",
    subtitle: [],
  },
  {
    icon: <Settings />,
    title: "Settings",
    id: "settings",
    link: "/dashboard/settings",
    subtitle: [],
  },
];

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      display: true,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
  maintainAspectRatio: false,
};

export const labels = ["Mon", "Tue", "Wen", "Thu", "Fri", "Sat", "Sun"];

export const data = {
  labels,
  datasets: [
    {
      label: "Last week",
      data: [],
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "This week",
      data: [],
      borderColor: "#35a2eb",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};
