
import { useState } from 'react'
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  InputAdornment,
  Link,
  FormHelperText
} from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { register } from "../actions/accountActions";
import { CheckCircleOutline } from '@mui/icons-material'

export default function Register() {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    organization: '',
    password1: '',
    password2: ''
  })

  const [errors, setErrors] = useState({
    first_name: '',
    last_name: '',
    email: '',
    organization: '',
    password1: '',
    password2: ''
  })

  const [registerSent, setRegisterSent] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const validateField = (name, value) => {
    let error = ''
    if (!value.trim()) {
      error = `Field is required`
      return error
    }
    switch (name) {
      case 'email':
        if (!/\S+@\S+\.\S+/.test(value)) {
          error = 'Email is invalid'
        }
        break
      case 'password1':
        if (value.length < 8) {
          error = 'Password must be at least 8 characters long'
        } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(value)) {
          error = 'Password must contain at least one uppercase letter, one lowercase letter, and one number'
        }
        break
      case 'password2':
        if (value !== formData.password1) {
          error = 'Passwords do not match'
        }
        break
      default:
        break
    }
    return error
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }))
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: validateField(name, value)
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const newErrors = {}
    let isValid = true

    Object.keys(formData).forEach(key => {
      const error = validateField(key, formData[key])
      newErrors[key] = error
      if (error) {
        isValid = false
      }
    })

    setErrors(newErrors)

    if (isValid) {
      try {
        if (formData.password1 !== formData.password2) {
          enqueueSnackbar("Not matched Password!", {
            variant: "error",
          });
        } else {
          await dispatch(register(formData));
          setRegisterSent(true);

          enqueueSnackbar("Check Your Email!", {
            variant: "success",
          });
        }
      } catch (error) {
        const message = error.response.data.detail;
        enqueueSnackbar(message, {
          variant: "error",
        });
      }
    } else {
      console.log('Form has errors, please correct them')
    }
  }

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        // Use 100vw and 100vh so the image fills the entire screen
        width: '100vw',
        height: '100vh',
        // Center children
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // Background image rules
        backgroundImage: 'url(/assets/images/account/SG_login_man_at_desk.webp)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}>

      {!registerSent
        ? <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'white',
            padding: 4,
            borderRadius: 4,
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            // Slightly transparent background if you want the image to show through
            // backgroundColor: 'rgba(255, 255, 255, 0.9)',
            width: '900px', // optional fixed width
          }}
        >
          <Typography variant="h2" component="h1" sx={{ fontWeight: 'bold', mb: 1 }}>
            Register
          </Typography>
          <Typography variant="h5" sx={{ mb: 4, color: 'text.secondary' }}>
            Register an Account
          </Typography>

          <Box sx={{
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
            gap: 3,
            width: '100%'
          }}>
            <TextField
              fullWidth
              name="first_name"
              placeholder="First Name *"
              value={formData.first_name}
              onChange={handleChange}
              error={!!errors.first_name}
              helperText={errors.first_name}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    👤
                  </InputAdornment>
                ),
              }}
              sx={{ '& .MuiOutlinedInput-root': { borderRadius: '4px' } }}
              required
            />
            <TextField
              fullWidth
              name="last_name"
              placeholder="Last Name *"
              value={formData.last_name}
              onChange={handleChange}
              error={!!errors.last_name}
              helperText={errors.last_name}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    👤
                  </InputAdornment>
                ),
              }}
              sx={{ '& .MuiOutlinedInput-root': { borderRadius: '4px' } }}
              required
            />
            <TextField
              fullWidth
              name="email"
              type="email"
              placeholder="Email *"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    ✉️
                  </InputAdornment>
                ),
              }}
              sx={{ '& .MuiOutlinedInput-root': { borderRadius: '4px' } }}
              required
            />
            <TextField
              fullWidth
              name="organization"
              placeholder="Organization *"
              value={formData.organization}
              onChange={handleChange}
              error={!!errors.organization}
              helperText={errors.organization}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    🏢
                  </InputAdornment>
                ),
              }}
              sx={{ '& .MuiOutlinedInput-root': { borderRadius: '4px' } }}
              required
            />
            <TextField
              fullWidth
              name="password1"
              type="password"
              placeholder="Password *"
              value={formData.password1}
              onChange={handleChange}
              error={!!errors.password1}
              helperText={errors.password1}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    🔒
                  </InputAdornment>
                ),
              }}
              sx={{ '& .MuiOutlinedInput-root': { borderRadius: '4px' } }}
              required
            />
            <TextField
              fullWidth
              name="password2"
              type="password"
              placeholder="Confirm Password *"
              value={formData.password2}
              onChange={handleChange}
              error={!!errors.password2}
              helperText={errors.password2}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    🔒
                  </InputAdornment>
                ),
              }}
              sx={{ '& .MuiOutlinedInput-root': { borderRadius: '4px' } }}
              required
            />
          </Box>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              borderRadius: '4px',
              padding: '12px',
              textTransform: 'none',
              fontSize: '1.1rem',
              backgroundColor: '#2196f3',
              '&:hover': {
                backgroundColor: '#1976d2',
              },
            }}
          >
            Register Now
          </Button>

          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Typography component="span" sx={{ mr: 1 }}>
              Already have an acount?
            </Typography>
            <Link
              href="/login"
              sx={{
                color: '#52A1E4',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              Login now
            </Link>
          </Box>
        </Box>
        : <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'white',
            padding: 4,
            borderRadius: "4px",
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            width: '600px'
          }}
        >
          <CheckCircleOutline color="primary" sx={{ width: "60px", height: "60px" }} />
          <Typography align="center" variant="h3" component="h1" sx={{ fontWeight: 'bold', mb: 1 }}>
            Registration Successful
          </Typography>
          <Typography align="center" variant="h5" sx={{ mb: 4, color: 'text.secondary' }}>
            Check your email to verify your account.
          </Typography>
        </Box>
      }
    </Container>
  )
}

