import React, { useState, useMemo, useEffect } from 'react';
import {
    Card,
    CardHeader,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Button,
    Chip,
    Box,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Collapse,
    Grid,
    Typography,
    Container,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Pagination,
    LinearProgress,
    Tooltip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DashboardLayout from '../../../components/Dashboard/layout';
import { deleteAnalysis, getAnalysis } from '../../../api';
import { useNavigate } from 'react-router-dom';
import { getFormattedDate } from '../../../utiles/general';
import { useSnackbar } from 'notistack';
import NewScanModal from '../../../components/new-scan-modal';
import { useSelector } from 'react-redux';

const Row = ({ scan, setPendingDelete }) => {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const modules = useSelector((state) => state.scan.config.modules);

    const getSingleDomainAnalysisHandler = (e, analysisId) => {
        navigate(`/dashboard/domain-analysis/${analysisId}`, { state: { poll: false } });
    };

    const getStatus = (status) => {
        let text = "";

        switch (status) {
            case 'initial_running':
                text = "Initial Running"
                break;
            case 'initial_complete':
                text = "Initial Complete"
                break;
            case 'further_running':
                text = "Detect Spoofing"
                break;
            case 'further_complete':
                text = "Spoofing Detection Completed"
                break;
            default:
        }

        return text;
    };

    return (
        <>
            <TableRow
                hover
                onClick={() => setOpen(!open)}
                sx={{ cursor: 'pointer', '& > *': { borderBottom: 'unset' } }}
            >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpen(!open);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    {getFormattedDate(scan.updated_at)}
                </TableCell>
                <TableCell>{scan.domain}</TableCell>
                <TableCell>
                    <Chip
                        label={getStatus(scan.status)}
                        size="small"
                        sx={{
                            backgroundColor: 'rgba(0, 0, 0, 0.08)',
                            color: 'rgba(0, 0, 0, 0.87)',
                            fontWeight: 500
                        }}
                    />
                </TableCell>
                <TableCell>{scan?.params?.modules?.length}</TableCell>
                <TableCell align="right">
                    <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                        <Button
                            variant="text"
                            size="small"
                            onClick={(e) => getSingleDomainAnalysisHandler(e, scan?.uid)}
                        >
                            View scan
                        </Button>
                        <IconButton
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                setPendingDelete(scan?.uid)
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                </TableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: open ? '#F7F8FB' : 'inherit' }}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 2, backgroundColor: '#F7F8FB' }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Enabled Modules
                            </Typography>
                            <Grid container spacing={1} sx={{ mb: 3, p: 2, borderRadius: 1 }}>
                                {scan?.params?.modules.map((module, index) => {
                                    const tooltip = modules?.filter(x => x.name === module.module_name)[0];
                                    return (
                                        <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
                                            <Tooltip title={tooltip?.description}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    p: 1,
                                                    border: '1px solid rgba(0, 0, 0, 0.12)',
                                                    borderRadius: 1,
                                                    minHeight: '40px',
                                                    textAlign: 'center',
                                                    fontSize: '0.75rem',
                                                    lineHeight: 1.2,
                                                    wordBreak: 'break-word',
                                                    backgroundColor: 'white'
                                                }}>
                                                    <Typography variant="caption">{module.module_name}</Typography>
                                                </Box>
                                            </Tooltip>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

const NewRecentScans = () => {
    const [statusFilter, setStatusFilter] = useState('');
    const [domainFilter, setDomainFilter] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'desc' });
    const [openDialog, setOpenDialog] = useState(false);
    const [pendingDelete, setPendingDelete] = useState("");
    const [data, setData] = useState([]);
    const [loadTable, setLoadTable] = useState(true);
    const [loading, setLoading] = useState(true);
    const [openNewScan, setOpenNewScan] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const { enqueueSnackbar } = useSnackbar();

    const handleSort = (key) => {
        setSortConfig((prevConfig) => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
        }));
    };

    const sortedAndFilteredScans = useMemo(() => {
        let filteredScans = data;

        if (statusFilter) {
            filteredScans = filteredScans.filter((scan) => scan.status === statusFilter);
        }

        if (domainFilter) {
            filteredScans = filteredScans.filter((scan) =>
                scan.domain.toLowerCase().includes(domainFilter.toLowerCase())
            );
        }

        return filteredScans.sort((a, b) => {
            if (sortConfig.key === 'date') {
                const dateA = new Date(a.updated_at);
                const dateB = new Date(b.updated_at);
                return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
            } else if (sortConfig.key === 'enabledMonitors') {
                return sortConfig.direction === 'asc'
                    ? a.params?.modules?.length - b.params?.modules?.length
                    : b.params?.modules?.length - a.params?.modules?.length;
            }
            return 0;
        });
    }, [statusFilter, domainFilter, sortConfig, data]);

    useEffect(() => {
        setLoading(true)

        const pageConfig = {
            page: currentPage,
            page_size: 20,
        }

        getAnalysis(pageConfig)
            .then((response) => {
                const data = response?.data?.results || [];
                setData(data);
                setLoadTable(false);
                setLoading(false)

                const pages = Math.ceil(response.data.count / 20);
                setTotalPages(pages)
            })
            .catch((err) => {
                setLoading(false)
                enqueueSnackbar("Unable to load recent analysis data", {
                    variant: "error",
                });
            });
    }, [enqueueSnackbar, currentPage]);

    useEffect(() => {
        if (pendingDelete) {
            setOpenDialog(true)
        }
    }, [pendingDelete]);

    const handleAnalysisDelete = () => {
        deleteAnalysis(pendingDelete).then(() => {
            setOpenDialog(false);
            setLoadTable(true);
            enqueueSnackbar("Analysis deleted successfully", {
                variant: "success",
            });
        }).catch(() => {
            enqueueSnackbar("Unable to delete analysis", {
                variant: "error",
            });
        })
    }

    const handlePageChange = (_, newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <DashboardLayout>
            <Container maxWidth="100%" sx={{
                p: 3,
            }}>
                <Card
                    sx={{
                        width: '100%',
                        maxWidth: '100%',
                        height: 'calc(100vh - 100px)',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
                        <CardHeader title="Recent Scans" sx={{ p: 0 }} />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => { setOpenNewScan(true) }}
                        >
                            New Scan
                        </Button>


                    </Box>
                    <Box>
                        {loading &&
                            <LinearProgress />
                        }
                    </Box>
                    <TableContainer
                        component={Paper}
                        sx={{ maxHeight: 'calc(100vh - 100px)', boxShadow: 'none' }}
                    >
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Last Scanned
                                        <IconButton size="small" onClick={() => handleSort('date')}>
                                            {sortConfig.key === 'date' &&
                                                (sortConfig.direction === 'asc' ? (
                                                    <ArrowUpwardIcon fontSize="small" />
                                                ) : (
                                                    <ArrowDownwardIcon fontSize="small" />
                                                ))}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            id="domain-header"
                                            label="Domain"
                                            placeholder="Search Domain"
                                            variant="standard"
                                            value={domainFilter}
                                            onChange={(e) => setDomainFilter(e.target.value)}
                                            fullWidth
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <FormControl fullWidth variant="standard">
                                            <InputLabel id="scan-status-filter-label">Scan Status</InputLabel>
                                            <Select
                                                labelId="scan-status-filter-label"
                                                id="scan-status-filter"
                                                value={statusFilter}
                                                onChange={(e) => setStatusFilter(e.target.value)}
                                                label="Scan Status"
                                            >
                                                <MenuItem value="">All</MenuItem>
                                                <MenuItem value="initial_running">Initial Running</MenuItem>
                                                <MenuItem value="initial_complete">Initial Complete</MenuItem>
                                                <MenuItem value="further_running">Detecting Spoofing</MenuItem>
                                                <MenuItem value="further_complete">Spoofing Detection Completed</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>
                                        Enabled Modules
                                        <IconButton size="small" onClick={() => handleSort('enabledMonitors')}>
                                            {sortConfig.key === 'enabledMonitors' &&
                                                (sortConfig.direction === 'asc' ? (
                                                    <ArrowUpwardIcon fontSize="small" />
                                                ) : (
                                                    <ArrowDownwardIcon fontSize="small" />
                                                ))}
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedAndFilteredScans.map((scan, index) => (
                                    <Row key={index} scan={scan} setPendingDelete={setPendingDelete} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Card>
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                    <Pagination
                        count={totalPages} // Replace with total page count from API
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                    />
                </Box>
            </Container>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Delete Scan</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this scan? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                    <Button onClick={() => handleAnalysisDelete()} color="error">
                        Delete Scan
                    </Button>
                </DialogActions>
            </Dialog>

            <NewScanModal
                open={openNewScan}
                onClose={() => setOpenNewScan(false)}
            />
        </DashboardLayout>
    );
};

export default NewRecentScans;


